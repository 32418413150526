import {Component} from '@angular/core'

import {ConfirmModalCfg} from '@core/schemes/ui/confirm-modal-cfg'
import {ModalService} from '@core/services/ui/modal.service'

@Component({
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {

  cfg: ConfirmModalCfg
  loading: boolean

  constructor(
    private modalService: ModalService,
  ) {
  }

  hide() {
    if (this.loading) return
    this.modalService.hide()
  }

  cancel() {
    this.cfg.onCancel?.()
    this.hide()
  }

  confirm() {
    if (this.cfg.observeBeforeConfirm) {
      this.loading = true

      this.cfg.observeBeforeConfirm.subscribe({
        next: () => {
          this.loading = false
          this.cfg.onConfirm?.()
          this.hide()
        },
        error: () => {
          this.loading = false
        },
      })

      return
    }

    this.cfg.onConfirm?.()
    this.hide()
  }

  get title() {
    return this.cfg.title
  }

  get message() {
    return this.cfg.message
  }

  get cancelText() {
    return this.cfg.cancelText ?? 'Отмена'
  }

  get confirmText() {
    return this.cfg.confirmText ?? 'ОК'
  }

  get confirmTextClass() {
    return this.cfg.confirmTextClass ?? ''
  }

  get icon() {
    return this.cfg.icon ?? 'blue'
  }
}
