import {Injectable} from '@angular/core'
import {ComponentType, Overlay, OverlayRef} from '@angular/cdk/overlay'
import {ComponentPortal} from '@angular/cdk/portal'

import {ModalCfg} from '@core/schemes/ui/modal-cfg'

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  private overlayRefs: OverlayRef[] = []

  constructor(
    private overlay: Overlay,
  ) {
  }

  show(component: ComponentType<any>, cfg: ModalCfg = {}) {
    const {initialState = {}} = cfg

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-modal-backdrop',
    })

    const portal = new ComponentPortal(component)
    const componentRef = overlayRef.attach(portal)

    Object.keys(initialState).forEach(key => {
      componentRef.instance[key] = initialState[key]
    })

    overlayRef.backdropClick().subscribe(() => {
      this.hide()
    })

    this.overlayRefs.push(overlayRef)
  }

  hide() {
    if (this.overlayRefs.length === 0) return
    this.overlayRefs.pop().dispose()
  }

  disposeAll() {
    this.overlayRefs.forEach(ref => ref.dispose())
    this.overlayRefs = []
  }
}
