<app-modal>
	<div>
		@switch (icon) {
			@case ('blue') {
				<div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-secondary">
					<i class="far fa-question text-blue"></i>
				</div>
			}
			@case ('red') {
				<div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-secondary">
					<i class="far fa-warning text-red"></i>
				</div>
			}
		}
		<div class="mt-3 text-center sm:mt-5">
			<h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{{ title }}</h3>
			@if (message) {
				<div class="mt-2">
					<p class="text-sm text-gray-500">
						{{ message }}
					</p>
				</div>
			}
		</div>
	</div>
	<div class="grid grid-cols-2 gap-3 mt-5 sm:mt-6">
		<button class="tw-btn tw-btn-gray col-span-full sm:col-span-1" [disabled]="loading" (click)="cancel()">{{ cancelText }}</button>
		<button class="tw-btn tw-btn-blue col-span-full sm:col-span-1 {{confirmTextClass}}" [disabled]="loading" (click)="confirm()">{{ confirmText }}</button>
	</div>
</app-modal>
