import {Component, ElementRef, Input, ViewChild} from '@angular/core'

import {ModalService} from '@core/services/ui/modal.service'

@Component({
  standalone: true,
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {

  @Input() contentClass = 'max-w-lg p-6'

  @ViewChild('content') content: ElementRef<HTMLDivElement>

  constructor(
    public modalService: ModalService,
  ) {
  }

  click(e?: any) {
    if (e && this.content.nativeElement.contains(e.target)) return
    this.hide()
  }

  hide() {
    this.modalService.hide()
  }
}
