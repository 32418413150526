<div class="fixed z-[9998] inset-0 w-screen overflow-y-auto animate-fade-in bg-gray-500 bg-opacity-75 transition-opacity">

	<div (click)="click($event)" class="flex min-h-full items-end justify-center p-4 text-center sm:items-center">

		<div #content class="animate-slide-in w-full relative rounded-lg bg-white text-left shadow-xl {{contentClass}}">

			<ng-content/>

			<div (click)="hide()" class="fixed top-6 right-6 w-12 h-12 flex items-center justify-center bg-gray-50 rounded-full shadow-md border border-gray-200 cursor-pointer hover:opacity-50 sm:absolute sm:top-0 sm:-right-[48px] sm:w-8 sm:h-8">
				<i class="far fa-xmark"></i>
			</div>

		</div>

	</div>

</div>
